import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// @ts-ignore
import Pdf from "../assets/portfolio.pdf";
// @ts-ignore
import MeJPG from "../assets/Apropos.jpg";

// @ts-ignore
import FavoriteIcon from "@mui/icons-material/Favorite";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { Button } from "@mui/material";

const MyProfile = () => {
  return (
    <Box
      id="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#E8D6C1",
        marginTop: "-7px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: "80%", md: "50%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%" },
              height: { md: "435px" },
              marginTop: "30px",
            }}
            component="img"
            src={MeJPG}
          />
          <Typography
            sx={{
              textAlign: "center",
              marginTop: "20px",
              fontSize: {
                lg: 25,
                md: 25,
                sm: 24,
                xs: 24,
              },
            }}
          >
            Cassandre THEZENAS
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: 20,
              color: "#815765",
            }}
          >
            Freelance | Evénementiel sportif
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: 20,
            }}
          >
            <a
              style={{ color: "black" }}
              href="mailto:cassandrethezenas@gmail.com"
              target="_top"
            >
              cassandrethezenas@gmail.com
            </a>
          </Typography>

          <Typography
            sx={{
              textAlign: "center",
              color: "#815765",
              fontSize: 20,
            }}
          >
            27 ans | Paris
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "50px",
          flexDirection: { xs: "column", md: "row" },
          marginTop: "50px",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            width: { xs: "auto", md: "400px" },
            height: "370px",
          }}
        >
          <CardContent sx={{ backgroundColor: "white" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "50px",
              }}
            >
              <FavoriteIcon />
            </Box>
            <Typography sx={{ fontSize: 15, textAlign: "center" }}>
              En 1996, je vois le jour dans les Yvelines (78). Je déborde
              d'énergie dès mon plus jeune âge, ce qui peut paraître évident
              quand on sait que je travaille dans l'événementiel. Je réalise des
              études de langues puis de communication événementielle. Dans un
              premier temps, je me dirige vers le B2B avec les salons, les
              conventions mais très rapidement je me rends compte que je ne suis
              pas épanouie.
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            width: { xs: "auto", md: "400px" },
            height: "370px",
            marginBottom: "50px",
          }}
        >
          <CardContent sx={{ backgroundColor: "white" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "50px",
              }}
            >
              <TrendingUpIcon />
            </Box>

            <Typography sx={{ fontSize: 15, textAlign: "center" }}>
              En Juillet 2020, je découvre l'événementiel sportif un peu par
              hasard lors d'un bénévolat. C'est la révélation. Je suis enfin
              animée par un secteur d'activité. Je poursuis donc mon chemin avec
              un service civique entremêlé de plusieurs bénévolats jusqu'à
              obtenir un poste à responsabilité sur Roland-Garros en 2022.
              L'expérience est tellement enrichissante que je ne veux pas me
              limiter à un seul évenement sportif et je décide de me lancer en
              freelance pour intervenir sur le plus de manifestations possibles.
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            width: { xs: "auto", md: "400px" },
            height: "370px",
            marginBottom: "50px",
          }}
        >
          <CardContent sx={{ backgroundColor: "white" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "50px",
              }}
            >
              <VolunteerActivismIcon />
            </Box>

            <Typography sx={{ fontSize: 14.5, textAlign: "center" }}>
              Mon entreprise se développe rapidement et je commence à partager
              mon quotidien sur les réseaux sociaux, avec toujours une pointe
              d'humour et de folie. ​ Mon objectif est désormais d'influencer et
              d'aider les étudiants, à mon échelle, à atteindre à leur tour le
              job de leurs rêves et vivre des émotions toujours plus intenses
              grâce à mon travail.
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Button
        sx={{
          alignSelf: "center",
          backgroundColor: "#CDAF8C",
          color: "#000",
          ":hover": {
            bgcolor: "#CDAF8C",
            color: "black",
          },
          marginBottom: "30px",
        }}
        href={Pdf}
        target="_blank"
      >
        Portfolio
      </Button>
    </Box>
  );
};

export default MyProfile;
