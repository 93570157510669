import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
// @ts-ignore
import Pdf from "../assets/cv.pdf";
// @ts-ignore
import CrossFigaroJPG from "../assets/crossFigaro2023.JPG";
// @ts-ignore
import MP2024JPG from "../assets/mp2024.JPG";
// @ts-ignore
import PSGClubTourJPG from "../assets/psgCLubTour.JPG";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import GroupsIcon from "@mui/icons-material/Groups";
import AnimatedNumber from "react-animated-number";
// @ts-ignore
import WelcomePNG from "../assets/welcome.png";
import { Link as L } from "react-router-dom";

const Home = () => {
  const scrollOnSection = () => {
    // @ts-ignore
    var elementPosition = document.getElementById("section").offsetTop;

    window.scrollTo({
      top: elementPosition - 140, //add your necessary value
      behavior: "smooth", //Smooth transition to roll
    });
  };

  return (
    <Box id="section">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f1eae1",
          justifyContent: "center",
        }}
      >
        <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
          <Box
            display="flex"
            sx={{
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "30%",
                height: "120px",
                display: { xs: "none", sm: "block" },
              }}
              component="img"
              src={WelcomePNG}
            />
            <Box width="500px">
              <Typography
                textAlign="center"
                sx={{
                  fontSize: {
                    lg: 25,
                    md: 25,
                    sm: 24,
                    xs: 24,
                  },
                }}
              >
                Ravie, moi c'est Cassandre.
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  marginTop: "20px",
                  fontStyle: "italic",
                  fontSize: {
                    lg: 20,
                    md: 20,
                    sm: 20,
                    xs: 20,
                  },
                }}
              >
                Bienvenue sur ma page. Je m'appelle Cassandre, j'approche
                (dangereusement?) de la trentaine et j'interviens dans
                l'événementiel sportif depuis près de cinq ans. J'ai vécu pas
                mal de choses au cours de ces années qui m'ont permis de
                m'épanouir, de me perfectionner et de prendre conscience que je
                faisais le métier dont j'avais toujours rêvé. Mais la route a
                été semée d'embûches. J'ai dû me montrer résiliente et
                déterminée pour atteindre mes objectifs.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#E8D6C1", textAlign: "center" }}>
        <Box>
          <Typography
            color="black"
            sx={{
              fontSize: {
                lg: 25,
                md: 25,
                sm: 24,
                xs: 24,
              },
            }}
          >
            À vos marques.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: { xs: "center", md: "" },
            justifyContent: { xs: "center", md: "space-around" },
            flexDirection: { xs: "column", md: "row" },
            marginTop: "50px",
          }}
        >
          <Box>
            <Card
              sx={{
                width: "300px",
                height: "280px",
                backgroundColor: "#E8D6C1",
              }}
            >
              <MenuBookIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: {
                      lg: 22,
                      md: 22,
                      sm: 20,
                      xs: 20,
                    },
                  }}
                >
                  Hospitalités - VIP
                </Typography>
                <Typography>
                  Mon sens du contact et mon sens du détail me permettent de
                  nouer aisément des liens avec les invités et de répondre au
                  mieux à leurs attentes avec toute la bienveillance dont je
                  dispose.
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box>
            <Card
              sx={{
                width: "300px",
                height: "280px",
                marginTop: { xs: "50px", md: "0px" },
              }}
            >
              <LightbulbIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Tour Leader
                </Typography>
                <Typography>
                  Formée initialement à l'événementiel à travers l'hôtessariat,
                  l'accueil de public n'a plus de secrets pour moi. Accompagner
                  et conseiller des touristes est un plaisir.
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box>
            <Card
              sx={{
                width: "300px",
                height: "280px",
                backgroundColor: "#E8D6C1",
                marginTop: { xs: "50px", md: "0px" },
              }}
            >
              <GroupsIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Logistique
                </Typography>
                <Typography>
                  Energique et débrouillarde, la logistique est une des
                  prestations que je réalise fréquemment. Elle me correspond
                  bien car elle me permet d'être en mouvement perpétuel et de
                  trouver des solutions inédites face à des imprévus.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Button
          sx={{
            backgroundColor: "white",
            color: "#CDAF8C",
            marginTop: "50px",
            marginBottom: "50px",
            ":hover": {
              bgcolor: "black",
              color: "white",
            },
          }}
          href={Pdf}
          target="_blank"
        >
          Télécharger mon CV
        </Button>
        {/* <Stripe /> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f1eae1",
          justifyContent: "center",
        }}
      >
        <Box sx={{ marginTop: "20px" }}>
          <Typography
            textAlign="center"
            sx={{
              fontSize: {
                lg: 25,
                md: 25,
                sm: 24,
                xs: 24,
              },
            }}
          >
            Prêt.e ?
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "50px",
            display: "flex",
            marginBottom: "50px",
            alignItems: { xs: "center", md: "" },
            justifyContent: { xs: "center", md: "space-around" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <L
            onClick={() => {
              scrollOnSection();
            }}
            to="/ebooks"
          >
            <Button
              sx={{
                backgroundColor: "white",
                color: "#CDAF8C",
                marginTop: "50px",
                marginBottom: "50px",
                ":hover": {
                  bgcolor: "black",
                  color: "white",
                },
              }}
            >
              Commander un e-book
            </Button>
          </L>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#E8D6C1",
          justifyContent: "center",
        }}
      >
        <Box sx={{ marginTop: "20px" }}>
          <Typography
            textAlign="center"
            sx={{
              fontSize: {
                lg: 25,
                md: 25,
                sm: 24,
                xs: 24,
              },
            }}
          >
            Partez !
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "50px",
            display: "flex",
            marginBottom: "50px",
            alignItems: { xs: "center", md: "" },
            justifyContent: { xs: "center", md: "space-around" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Button
            sx={{
              backgroundColor: "white",
              color: "#CDAF8C",
              marginTop: "50px",
              marginBottom: "50px",
              ":hover": {
                bgcolor: "black",
                color: "white",
              },
            }}
            href="https://www.instagram.com/broadcass_/"
            target="_blank"
          >
            Me rejoindre
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f1eae1",
          justifyContent: "center",
        }}
      >
        <Box sx={{ marginTop: "20px" }}>
          <Typography
            textAlign="center"
            sx={{
              fontSize: {
                lg: 25,
                md: 25,
                sm: 24,
                xs: 24,
              },
            }}
          >
            Et ...
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "50px",
            display: "flex",
            marginBottom: "50px",
            alignItems: { xs: "center", md: "" },
            justifyContent: { xs: "center", md: "space-around" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Button
            sx={{
              backgroundColor: "white",
              color: "#CDAF8C",
              marginTop: "50px",
              marginBottom: "50px",
              ":hover": {
                bgcolor: "black",
                color: "white",
              },
            }}
            href={"https://go.formulaire.info/form?p=d2FfA5Zs"}
            target="_blank"
          >
            Rejoins ma newsletter !
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
