import { Box, Modal, Typography } from "@mui/material";
import * as React from "react";
import Stripe from "./stripe";
import { Template } from "../types";
import { useState } from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  template: Template;
}

const PaiementModal = ({
  open,
  onClose,
  title,
  description,
  template,
}: Props) => {
  const [amount, setAmount] = useState<number>(template.amount);

  const handlePromo = (amountWithPromo: number) => {
    setAmount(amountWithPromo);
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", md: "500px" },
          backgroundColor: "white",
          border: "2px solid #000",
          padding: "10px",
          boxShadow: "24",
          maxHeight: "90%",
          overflow: "auto",
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{ mt: 2, color: "#CDAF8C" }}
        >
          {description}
          {amount / 100} Euros
        </Typography>
        <Stripe onClose={onClose} template={template} setAmount={handlePromo} />
      </Box>
    </Modal>
  );
};

export default PaiementModal;
