import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Link as L } from "react-router-dom";
// @ts-ignore
import LogoPng from "../assets/logo_CT.svg";
// @ts-ignore
import LinkdinPng from "../assets/linkedin.png";
// @ts-ignore
import InstaPng from "../assets/insta.png";
// @ts-ignore
import TikTokPng from "../assets/tiktok.png";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  w: () => Window;
}

const Header = (props: Props) => {
  const drawerWidth = 240;
  const linkStyle = {
    color: "black",
    fontSize: "15px",
    textDecoration: "none",
    fontWeight: "semi-bold",
    margin: "10px",
  };
  const { w } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <List>
        <ListItem disablePadding>
          <L
            onClick={() => {
              scrollOnSection();
            }}
            to="/home"
            style={linkStyle}
          >
            <ListItemText primary="Accueil" />
          </L>
        </ListItem>
        <ListItem disablePadding>
          <L
            onClick={() => {
              scrollOnSection();
            }}
            to="/ebooks"
            style={linkStyle}
          >
            <ListItemText primary="Outil de productivité" />
          </L>
        </ListItem>
        <ListItem disablePadding>
          <L
            onClick={() => {
              scrollOnSection();
            }}
            to="/clients"
            style={linkStyle}
          >
            <ListItemText primary="Références" />
          </L>
        </ListItem>
        <ListItem disablePadding>
          <L
            onClick={() => {
              scrollOnSection();
            }}
            to="/myprofile"
            style={linkStyle}
          >
            <ListItemText primary="A Propos" />
          </L>
        </ListItem>
        <ListItem disablePadding>
          <L
            onClick={() => {
              scrollOnSection();
            }}
            to="/contact"
            style={linkStyle}
          >
            <ListItemText primary="Contact" />
          </L>
        </ListItem>
      </List>
    </Box>
  );

  const container = w !== undefined ? () => w().document.body : undefined;
  const scrollOnSection = () => {
    // @ts-ignore
    var elementPosition = document.getElementById("section").offsetTop;

    window.scrollTo({
      top: elementPosition - 140, //add your necessary value
      behavior: "smooth", //Smooth transition to roll
    });
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ backgroundColor: "#fff" }}>
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              component="img"
              sx={{
                display: { xs: "flex", sm: "flex" },
                width: { xs: "120px", sm: "200px" },
                height: { xs: "120px", sm: "140px" },
              }}
              src={LogoPng}
            />
            <Box
              sx={{
                display: { md: "flex", sm: "none" },
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  display: { xs: "none", sm: "flex" },
                  fontSize: {
                    lg: 20,
                    md: 18,
                    sm: 15,
                    xs: 10,
                  },
                }}
                color="black"
              >
                Cassandre THEZENAS
              </Typography>
              <Typography
                sx={{
                  display: { xs: "none", sm: "flex" },
                  fontSize: {
                    lg: 16,
                    md: 15,
                    sm: 15,
                    xs: 10,
                  },
                }}
                color="black"
                variant="h6"
              >
                Freelance événementiel sportif
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <L
                  onClick={() => {
                    scrollOnSection();
                  }}
                  to="/home"
                  style={linkStyle}
                >
                  Accueil
                </L>
                <L
                  onClick={() => {
                    scrollOnSection();
                  }}
                  to="/ebooks"
                  style={linkStyle}
                >
                  Outils de productivité
                </L>
                <L
                  onClick={() => {
                    scrollOnSection();
                  }}
                  to="/clients"
                  style={linkStyle}
                >
                  Références
                </L>
                <L
                  onClick={() => {
                    scrollOnSection();
                  }}
                  to="/myprofile"
                  style={linkStyle}
                >
                  A Propos
                </L>
                <L
                  onClick={() => {
                    scrollOnSection();
                  }}
                  to="/contact"
                  style={linkStyle}
                >
                  Contact
                </L>
              </Box>
              <Box sx={{ display: "flex", marginTop: "10px" }}>
                <Link
                  target="_blank"
                  href="https://www.linkedin.com/in/cassandre-thezenas/"
                >
                  <Box
                    sx={{ height: "32px" }}
                    component="img"
                    src={LinkdinPng}
                  />
                </Link>
                <Link
                  target="_blank"
                  href="https://www.instagram.com/broadcass_/"
                >
                  <Box sx={{ height: "32px" }} component="img" src={InstaPng} />
                </Link>
                <Link target="_blank" href="https://www.tiktok.com/@broadcass">
                  <Box
                    sx={{ height: "32px" }}
                    component="img"
                    src={TikTokPng}
                  />
                </Link>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Header;
