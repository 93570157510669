import * as React from "react";
import Box from "@mui/material/Box";
// @ts-ignore
import BannerJpg from "../assets/banner2.webp";
// @ts-ignore
import BannerMobileJpg from "../assets/bannerMobile.webp";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { isMobileOnly } from "react-device-detect";

const Banner = () => {
  const scrollOnSection = () => {
    // @ts-ignore
    var elementPosition = document.getElementById("section").offsetTop;

    window.scrollTo({
      top: elementPosition - 140, //add your necessary value
      behavior: "smooth", //Smooth transition to roll
    });
  };
  return (
    <Box sx={{ backgroundColor: "#f1eae1" }}>
      <Box display="flex">
        <Typography
          sx={{
            display: "block",
            top: "54%",
            position: "absolute",
            color: "#f1eae1",
            left: { xs: "0%", sm: "20%" },
            right: { xs: "0%", sm: "auto" },
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: { xs: "center", sm: "" },
            fontSize: {
              lg: 26,
              md: 26,
              sm: 22,
              xs: 22,
            },
            fontFamily: '"Palatino, "URW Palladio L", "sans-serif"',
          }}
        >
          Cassandre THEZENAS
        </Typography>
        <Typography
          sx={{
            display: "block",
            top: { xs: "60%", sm: "60%" },
            position: "absolute",
            color: "white",
            left: { xs: "0%", sm: "20%" },
            right: { xs: "0%", sm: "auto" },
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            fontSize: {
              lg: 22,
              md: 22,
              sm: 20,
              xs: 18,
            },
          }}
        >
          Freelance événementiel sportif
        </Typography>
        <Button
          sx={{
            position: "absolute",
            top: "90%",
            left: { xs: "30%", sm: "20%" },
            right: { xs: "30%", sm: "auto" },
            backgroundColor: "#CDAF8C",
            color: "#000",
            ":hover": {
              bgcolor: "#CDAF8C",
              color: "black",
            },
          }}
          href="/#/myprofile"
          onClick={() => {
            scrollOnSection();
          }}
        >
          En savoir plus
        </Button>
      </Box>

      <Box
        sx={{ width: { xs: "100vw", sm: "100%" }, height: "100vh" }}
        component="img"
        loading="lazy"
        src={isMobileOnly ? BannerMobileJpg : BannerJpg}
      />
    </Box>
  );
};

export default Banner;
