import "./App.css";
import AppRouter from "./AppRouter";
import Banner from "./components/banner";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: `"Palatino", "URW Palladio L", sans-serif`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Box>
          <Banner />
        </Box>
        <AppRouter />
        <Box>
          <footer>
            <Box
              sx={{
                display: "flex",
                marginTop: "20px",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: { xs: "center", md: "center" },
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                Freelance événementiel sportif
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  marginLeft: { xs: "none", md: "20px" },
                }}
              >
                Mail :{" "}
                <a
                  style={{ color: "black" }}
                  href="mailto:cassandrethezenas@gmail.com"
                  target="_top"
                >
                  cassandrethezenas@gmail.com
                </a>
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Copyright &copy; THEZENAS Cassandre{" "}
              </Typography>
            </Box>
          </footer>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
