import Box from "@mui/material/Box";
// @ts-ignore
import * as React from "react";

import { useState } from "react";

interface Props {
  image: string;
  descriptionImage?: string;
}

const EbookCard = (props: Props) => {
  const [openDescription, setOpenDescription] = useState(false);

  return (
    <Box
      onMouseEnter={() => setOpenDescription(true)}
      onMouseLeave={() => setOpenDescription(false)}
      sx={{ position: "relative" }}
    >
      {(!openDescription || !props.descriptionImage) && (
        <Box
          sx={{
            borderRadius: "15px 15px 15px 15px",
            width: { md: "500px", xs: "400px" },
          }}
          component="img"
          src={props.image}
        />
      )}
      {openDescription && props.descriptionImage && (
        <Box
          sx={{
            width: "300px",
            height: "300px",
            borderRadius: "15px 15px 15px 15px",
          }}
          component="img"
          src={props.descriptionImage}
        />
      )}
    </Box>
  );
};

export default EbookCard;
