import Box from "@mui/material/Box";

import * as React from "react";
import { useState } from "react";
import { Button, CardContent, Typography } from "@mui/material";

interface Props {
  title: string;
  subTitle: string;
  image: string;
}

const ClientCard = (props: Props) => {
  const [openDescription, setOpenDescription] = useState(false);

  return (
    <Box
      onMouseEnter={() => setOpenDescription(true)}
      onMouseLeave={() => setOpenDescription(false)}
      sx={{ position: "relative" }}
    >
      <Box
        sx={{
          width: "300px",
          height: "300px",
          borderRadius: "15px 15px 15px 15px",
        }}
        component="img"
        src={props.image}
      />
      {openDescription && (
        <CardContent
          onClick={() => setOpenDescription(false)}
          sx={{
            position: "absolute",
            backgroundColor: "black",
            top: "0%",
            width: "300px",
            height: "300px",
            borderRadius: "15px 15px 15px 15px",
            opacity: 0.6,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                variant="h5"
                sx={{ marginBottom: "100px" }}
                color="white"
              >
                {props.title}
              </Typography>
              <Typography variant="h6" color="white">
                {props.subTitle}
              </Typography>
            </Box>
            <Button onClick={() => setOpenDescription(false)} color="primary">
              Fermer
            </Button>
          </Box>
        </CardContent>
      )}
    </Box>
  );
};

export default ClientCard;
