import * as React from "react";
import Carousel from "react-material-ui-carousel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import HubIcon from "@mui/icons-material/Hub";
import FilePresentIcon from "@mui/icons-material/FilePresent";
// @ts-ignore
import Eureka1 from "../assets/eureka1.jpg";

const Juniors = () => {
  return (
    <Box id="section">
      <Box
        sx={{
          backgroundColor: "#f1eae1",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: "80%", md: "50%" },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              marginTop: "20px",
              colo: "black",
              fontSize: {
                lg: 25,
                md: 25,
                sm: 24,
                xs: 24,
              },
            }}
          >
            Les Juniors quésako ?
          </Typography>
          <Typography fontWeight="bold" marginTop="20px" color="black">
            Les juniors c'est un regroupement d'élèves, de bénévoles, de motivés
            qui participent à des ateliers impactants au service des événements
            sportifs. Le but : trouver l'idée innovante à mettre en place pour
            booster l'expérience utilisateurs sur les événements sportifs.
          </Typography>
          <Typography marginTop="20px" color="black">
            En partenariat avec des écoles de marketing sportif.
          </Typography>
          <Typography marginTop="20px" marginBottom="50px" color="black">
            Mon objectif est tant d'engager les fans sur les événements sportifs
            qu'ils affectionnent que de favoriser l'insertion professionnelle
            les chefs de projets de demain dans ce secteur concurrentiel à
            travers des ateliers en groupe. Nous oeuvrons ensemble pour proposer
            des solutions innovantes et impactantes (environnementales et
            sociales) sur les événements que vous connaissez tous pour les
            rendre encore plus uniques.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1eae1",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            gap: { xs: "0px", md: "100px" },
            alignItems: { xs: "center", md: "" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{ width: "300px", marginBottom: { xs: "50px", md: "0px" } }}>
            <Card
              sx={{
                width: "300px",
                height: "280px",
              }}
            >
              <VerifiedUserIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography
                  sx={{
                    fontSize: {
                      lg: 22,
                      md: 22,
                      sm: 20,
                      xs: 20,
                    },
                  }}
                >
                  Audit
                </Typography>
                <Typography>
                  Les organisateurs viennent vers moi car ils souhaitent
                  réinventer leurs événements de manière plus impactante.
                  J'entame, en plus de ça, des recherches approfondies sur les
                  précédentes éditions.
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: "300px", marginBottom: { xs: "50px", md: "0px" } }}>
            <Card
              sx={{
                width: "300px",
                height: "280px",
              }}
            >
              <HubIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography
                  sx={{
                    fontSize: {
                      lg: 22,
                      md: 22,
                      sm: 20,
                      xs: 20,
                    },
                  }}
                >
                  Accompagnement
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                  }}
                >
                  Je partage fréquemment mon quotidien dans l'événementiel
                  sportif sur mes réseaux sociaux pour informer les étudiants.
                  J'interviens même dans des écoles. J'ai également créé des
                  E-books (onglet e-books du site) pour aider les étudiants à
                  trouver du travail sur et après les Jeux Olympiques et
                  Paralympiques.
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: "300px" }}>
            <Card
              sx={{
                width: "300px",
                height: "280px",
              }}
            >
              <FilePresentIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: {
                      lg: 22,
                      md: 22,
                      sm: 20,
                      xs: 20,
                    },
                  }}
                >
                  Mise en place
                </Typography>
                <Typography>
                  Je vous présente nos idées et nous oeuvrons pour les mettre en
                  place sur la prochaine édition. Une enquête de satisfaction
                  sera réalisée pour constater du retour sur investissement.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              backgroundColor: "#5B2F2C",
              color: "white",
              marginTop: "50px",
              marginBottom: "50px",
              ":hover": {
                bgcolor: "white",
                color: "#5B2F2C",
              },
            }}
          >
            Inscrivez vous
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          height: "480px",
          backgroundImage: `url(${Eureka1})`,
          backgroundPosition: "bottom",
        }}
      >
        <Box sx={{ height: "inherit" }}>
          <Carousel
            sx={{ height: "inherit" }}
            animation="fade"
            navButtonsAlwaysVisible
            autoPlay={false}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: { xs: "70%", md: "50%" },
                margin: { xs: "140px auto", md: "90px auto" },
              }}
            >
              <Card>
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: 16,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Les juniors x école Sup de sport (78)
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "#CDAF8C",
                    }}
                  >
                    Objectif : rendre la course de cyclisme ultra-trail, Race
                    Across, plus éco-responsable
                  </Typography>
                  <Typography
                    sx={{
                      display: { xs: "none", md: "block" },
                      marginTop: "20px",
                      fontSize: {
                        lg: 16,
                        md: 13,
                        sm: 13,
                        xs: 13,
                      },
                    }}
                  >
                    Pendant plusieurs mois, j'ai eu l'opportunité d'accompagner
                    une petite dizaine d'étudiants sur la dimension
                    éco-responsable. La course ultra-trail de cyclisme qui
                    connaissait sa deuxième édition dans l'oise s'est montrée
                    très intéressée par notre valeur ajoutée. Plusieurs heures
                    de travail plus tard, nous voilà à l'origine d'un
                    partenarriat anti-gaspi basé sur l'utilisation de fruits
                    moches sur les ravitaillements de ma course mais aussi d'un
                    partenariat proposant des boissons gazeuses à base de sève
                    d'érables pour limiter l'usage de bouteilles plastiques.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: 16,
                        md: 13,
                        sm: 13,
                        xs: 13,
                      },
                      textAlign: "center",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Prestataires ayant collaborés : Beesk, Drinksev
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: 16,
                        md: 13,
                        sm: 13,
                        xs: 13,
                      },
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Race Across, Chantilly, Avril 2023
                  </Typography>
                </CardContent>
              </Card>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: { xs: "70%", md: "50%" },
                margin: { xs: "140px auto", md: "90px auto" },
              }}
            >
              <Card>
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: 16,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Les juniors x école Sup de sport (78)
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "#CDAF8C",
                    }}
                  >
                    Objectif : rendre la course de cyclisme ultra-trail, Race
                    Across, plus éco-responsable
                  </Typography>
                  <Typography
                    sx={{
                      display: { xs: "none", md: "block" },
                      marginTop: "20px",
                      fontSize: {
                        lg: 16,
                        md: 13,
                        sm: 13,
                        xs: 13,
                      },
                    }}
                  >
                    Pendant plusieurs mois, j'ai eu l'opportunité d'accompagner
                    une petite dizaine d'étudiants sur la dimension
                    éco-responsable. La course ultra-trail de cyclisme qui
                    connaissait sa deuxième édition dans l'oise s'est montrée
                    très intéressée par notre valeur ajoutée. Plusieurs heures
                    de travail plus tard, nous voilà à l'origine d'un
                    partenarriat anti-gaspi basé sur l'utilisation de fruits
                    moches sur les ravitaillements de ma course mais aussi d'un
                    partenariat proposant des boissons gazeuses à base de sève
                    d'érables pour limiter l'usage de bouteilles plastiques.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: 16,
                        md: 13,
                        sm: 13,
                        xs: 13,
                      },
                      textAlign: "center",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    Prestataires ayant collaborés : Beesk, Drinksev
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        lg: 16,
                        md: 13,
                        sm: 13,
                        xs: 13,
                      },
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Race Across, Chantilly, Avril 2023
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Carousel>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#CDAF8C",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            maxWidth: { xs: "80%", md: "50%" },
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              marginTop: "50px",
              marginBottom: "50px",
              textAlign: "cente",
              fontSize: {
                lg: 25,
                md: 25,
                sm: 24,
                xs: 24,
              },
            }}
          >
            Vous êtes organisateurs ?
          </Typography>
          <Typography
            sx={{
              textAlign: "cente",
              fontSize: 18,
            }}
          >
            Votre événement souhaite se renouveler ? Vous souhaitez davantage
            engager vos fans ? Vous êtes soucieux des problématiques
            environnementales causées sur votre événement ?
          </Typography>
          <Button
            sx={{
              backgroundColor: "#5B2F2C",
              color: "white",
              marginTop: "50px",
              marginBottom: "50px",
              ":hover": {
                bgcolor: "white",
                color: "#5B2F2C",
              },
            }}
          >
            Demandez un audit
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1eae1",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            maxWidth: { xs: "80%", md: "50%" },
          }}
        >
          <Typography
            sx={{
              marginTop: "50px",
              marginBottom: "50px",
              textAlign: "center",
              fontSize: {
                lg: 25,
                md: 25,
                sm: 24,
                xs: 24,
              },
            }}
          >
            Vous êtes fans, bénévoles, étudiants, freelances ?
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: 18,
            }}
          >
            Vous avez du temps libre et vous souhaitez investir ce temps pour
            réaliser des actions impactantes ? Vous voulez ajouter LA ligne à
            votre CV ? Vous êtes fans de course et vous souhaiteriez contribuer
            à l'organisation de vos événements préférés ?
          </Typography>
          <Button
            sx={{
              backgroundColor: "#5B2F2C",
              color: "white",
              marginTop: "50px",
              marginBottom: "50px",
              ":hover": {
                bgcolor: "white",
                color: "#5B2F2C",
              },
            }}
          >
            Inscrivez vous
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Juniors;
