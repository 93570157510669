import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import Divider from "@mui/material/Divider";
// @ts-ignore
import CleanGirlERA from "../assets/cleanGirlEra.png";
// @ts-ignore
import EtudiantPlanner from "../assets/etudiantPlanner.png";
// @ts-ignore
import BusinessPlanner from "../assets/businessPlanner.png";
// @ts-ignore
import GirlBossPlanner from "../assets/girlBossPlanner.png";
import PaiementModal from "./paiementModal";
import { useState } from "react";
import { Template } from "../types";
import FreeModal from "./freeModal";
import EbookCard from "./ebookCard";
import Carousel from "react-material-ui-carousel";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import GroupsIcon from "@mui/icons-material/Groups";
import FemaleIcon from "@mui/icons-material/Female";
import SchoolIcon from "@mui/icons-material/School";
import BusinessIcon from "@mui/icons-material/Business";
import GradeIcon from "@mui/icons-material/Grade";
// @ts-ignore
import Eureka1 from "../assets/eureka1.jpg";
// @ts-ignore
import CarousselPimperCandidature from "../assets/carousselPimperCandidature.jpg";
// @ts-ignore
import CarousselApresJO from "../assets/carousselApresJO.jpg";
// @ts-ignore
import CarousselPlanner from "../assets/carousselPlanner.jpg";

const Ebooks = () => {
  const [openFreeModal, setOpenFreeModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const [template, setTemplate] = useState<Template>();

  return (
    <Box id="section" sx={{ backgroundColor: "#f1eae1", textAlign: "center" }}>
      <Box>
        <Box
          sx={{
            backgroundImage: `url(${CarousselPlanner})`,
            backgroundPosition: "center", // Centrer l'image dans le conteneur
            backgroundSize: "cover", // Ajuste l'image pour qu'elle couvre tout le conteneur
            backgroundRepeat: "no-repeat", // Évite la répétition si l'image est petite
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: { md: "50%" },
              margin: { md: "0px auto" },
            }}
          >
            <Card>
              <CardContent>
                <Typography
                  sx={{
                    fontSize: 16,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Si tu manques d'organisation et de productivité
                </Typography>
                <Typography
                  sx={{
                    fontSize: 15,
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#CDAF8C",
                  }}
                >
                  Planner 2025
                </Typography>
                <Typography
                  sx={{
                    // display: { xs: "none", md: "block" },
                    marginTop: "20px",
                    fontSize: {
                      lg: 16,
                      md: 13,
                      sm: 13,
                      xs: 13,
                    },
                  }}
                >
                  Si tu as suivi mon parcours, tu as compris que j'ai dû m'armer
                  de résilience, m'entourer des bonnes personnes, croire en mon
                  potentiel pour surmonter toutes les épreuves auxquelles j'ai
                  été confronté et réussir sur le plan professionnel et
                  personnel. Aujourd'hui, j'ai trouvé ma voie et mon équilibre.
                  Cela n'aurait pas été possible sans certains éléments que tu
                  retrouveras dans ce planner. Que tu sois entrepreneur.e, que
                  tu aies des projets, ou que tu veuilles organiser ton
                  ascension vers la vie que t mérites, ce planner n'est qu'un
                  agenda, il est un réel outil de productivité qui te fera
                  grandir. De nombreux exercices de productivité et
                  d'organisation ainsi que des conseils de lectures de
                  développement personnels y sont inclus.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      lg: 16,
                      md: 13,
                      sm: 13,
                      xs: 13,
                    },
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  Besoin : Le planner pour les business women en devenir.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      lg: 16,
                      md: 13,
                      sm: 13,
                      xs: 13,
                    },
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  ​Disponible très bientôt
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
      <Typography color="black" variant="h4" sx={{ mt: 5 }}>
        Outils de travail
      </Typography>
      <Typography color="black" sx={{ mt: 5 }}>
        Parce qu'avec des techniques de travail et une bonne visualisation de
        tes objectifs, tu pourras aller loin.
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "center", md: "" },
          justifyContent: { xs: "center", md: "space-around" },
          flexDirection: { xs: "column", md: "row" },
          marginTop: "50px",
        }}
      >
        <Box>
          <Card
            sx={{
              width: "300px",
              height: "290px",
              backgroundColor: "#E8D6C1",
            }}
          >
            <MenuBookIcon sx={{ marginTop: "10px" }} />
            <CardContent>
              <Typography
                gutterBottom
                sx={{
                  fontSize: {
                    lg: 22,
                    md: 22,
                    sm: 20,
                    xs: 20,
                  },
                }}
              >
                Mon histoire
              </Typography>
              <Typography>
                Pour vivre pleinement de mon activité, j'ai décidé de faire des
                sacrifices. Aujourd'hui, mon entreprise prospère et sans ces
                choix, sans un mental d'acier et sans une organisation adaptée,
                mes projets auraient eu une toute autre fin.
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box>
          <Card
            sx={{
              width: { xs: "300px", md: "380px" },
              height: "390px",
              marginTop: { xs: "50px", md: "0px" },
            }}
          >
            <LightbulbIcon sx={{ marginTop: "10px" }} />
            <CardContent>
              <Typography gutterBottom variant="h5">
                Mes expériences
              </Typography>
              <Typography>
                En 2019 j'obtiens mon diplôme. S'ensuit dès lors une période de
                chômage qui durera quelques mois. J'obtiens un poste à
                responsabilité très gracieusement payé dans lequel je ne suis
                pas épanouie. Je prends le temps de faire une introspection, je
                m'intéresse à d'autres secteurs d'activités et je découvre
                l'événementiel sportif. Après plusieurs iobs sous payés et
                quelques bénévolats, je créé mon entreprise. 2 ans plus tard,
                mon business est florissant mais cela demande une organisation
                et un état d'esprit particulier.
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box>
          <Card
            sx={{
              width: "300px",
              height: "290px",
              backgroundColor: "#E8D6C1",
              marginTop: { xs: "50px", md: "0px" },
            }}
          >
            <GroupsIcon sx={{ marginTop: "10px" }} />
            <CardContent>
              <Typography gutterBottom variant="h5">
                Mes outils
              </Typography>
              <Typography>
                Pour ne pas baisser les bras, ne pas me laisser ronger par le
                stress, ou encore ne pas m'emmêler les pinceaux, je me suis
                appuyée sur de nombreux outils de productivité que je vous
                propose aujourd'hui pour vous permettre d'atteindre, à votre
                tour, vos objectifs.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Button
        sx={{
          backgroundColor: "white",
          color: "#CDAF8C",
          marginTop: "50px",
          marginBottom: "50px",
          ":hover": {
            bgcolor: "black",
            color: "white",
          },
        }}
        href={"https://go.formulaire.info/form?p=d2FfA5Zs"}
        target="_blank"
      >
        Rejoins ma newsletter !
      </Button>
      <Divider />
      <Typography color="black" variant="h4" sx={{ mt: 5 }}>
        Tous les outils de productivité
      </Typography>
      {openModal && template && (
        <PaiementModal
          title={modalTitle}
          description={modalDescription}
          open={openModal}
          onClose={() => setOpenModal(false)}
          template={template}
        />
      )}
      {openFreeModal && template && (
        <FreeModal
          title={modalTitle}
          open={openFreeModal}
          onClose={() => setOpenFreeModal(false)}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center" },
          gap: "50px",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "" },
          marginTop: "50px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <Card
              sx={{
                width: "300px",
                height: "290px",
                backgroundColor: "#f4eeee",
                marginTop: { xs: "50px", md: "0px" },
              }}
            >
              <GradeIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Template Girlboss planner
                </Typography>
                <Typography>
                  Ce template est conçu pour accompagner les femmes dans la
                  gestion de leurs projets personnels ou professionnels. Cet
                  espace de travail design combine organisation, inspiration et
                  motivation.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <EbookCard image={GirlBossPlanner} />
          <Button
            sx={{
              backgroundColor: "white",
              color: "#CDAF8C",
              marginTop: "50px",
              marginBottom: "50px",
              ":hover": {
                bgcolor: "black",
                color: "white",
              },
            }}
            onClick={() => {
              setModalTitle("Commande - Template Girlboss planner");
              setModalDescription(
                "Paiement en CB, virement, Stripe. Valeur : "
              );
              setOpenModal(true);
              setTemplate({ amount: 2700, type: "GIRL_BOSS" });
            }}
          >
            Commander
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center" },
          gap: "50px",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "" },
          backgroundColor: "#edf3ec",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <Card
              sx={{
                width: "300px",
                height: "290px",
                backgroundColor: "#e2e7df",
                marginTop: { xs: "50px", md: "0px" },
              }}
            >
              <FemaleIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Template Clean girl era
                </Typography>
                <Typography>
                  Découvre un template Notion conçu spécialement pour les femmes
                  qui souhaitent mettre leur bien-être au premier plan. Cet
                  espace de travail t'accompagnera dans ta routine quotidienne
                  de soins du visage, d'alimentation saine et d'activités
                  sportives.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <EbookCard image={CleanGirlERA} />
          <Button
            sx={{
              backgroundColor: "white",
              color: "#CDAF8C",
              marginTop: "50px",
              marginBottom: "50px",
              ":hover": {
                bgcolor: "black",
                color: "white",
              },
            }}
            onClick={() => {
              setModalTitle("Commande - Template Clean girl era");
              setModalDescription(
                "Paiement en CB, virement, Stripe. Valeur : "
              );
              setOpenModal(true);
              setTemplate({ amount: 2700, type: "CLEAN_GIRL_ERA" });
            }}
          >
            Commander
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center" },
          gap: "50px",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "" },
          backgroundColor: "#e6e5dd",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <Card
              sx={{
                width: "300px",
                height: "290px",
                backgroundColor: "#f4eeee",
                marginTop: { xs: "50px", md: "0px" },
              }}
            >
              <SchoolIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Template Planner étudiants
                </Typography>
                <Typography>
                  L'organisateur ultime pour planifier toute vie étudiante. Des
                  projets de groupe à la prise de notes en passant par le suivi
                  de tes candidatures, plus rien ne t'empêchera de réussir ton
                  année.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <EbookCard image={EtudiantPlanner} />
          <Button
            sx={{
              backgroundColor: "white",
              color: "#CDAF8C",
              marginTop: "50px",
              marginBottom: "50px",
              ":hover": {
                bgcolor: "black",
                color: "white",
              },
            }}
            onClick={() => {
              setModalTitle("Commande -  Template Planner étudiants");
              setModalDescription(
                "Paiement en CB, virement, Stripe. Valeur : "
              );
              setOpenModal(true);
              setTemplate({ amount: 1700, type: "ETUDIANT" });
            }}
          >
            Commander
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center" },
          gap: "50px",
          backgroundColor: "#e7f3f8",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <Card
              sx={{
                width: "300px",
                height: "290px",
                backgroundColor: "#e7f3f8",
                marginTop: { xs: "50px", md: "0px" },
              }}
            >
              <BusinessIcon sx={{ marginTop: "10px" }} />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Template business planner
                </Typography>
                <Typography>
                  Optimise la gestion de tes projets avec ce template Notion
                  conçu spécifiquement pour les (futurs) entrepreneurs et les
                  professionnels.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <EbookCard image={BusinessPlanner} />
          <Button
            sx={{
              backgroundColor: "white",
              color: "#CDAF8C",
              marginTop: "50px",
              marginBottom: "50px",
              ":hover": {
                bgcolor: "black",
                color: "white",
              },
            }}
            onClick={() => {
              setModalTitle("Commande -  Template business planner");
              setModalDescription(
                "Paiement en CB, virement, Stripe. Valeur : "
              );
              setOpenModal(true);
              setTemplate({ amount: 2700, type: "BUSINESS" });
            }}
          >
            Commander
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Ebooks;
