import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import * as React from "react";
import { useForm } from "@formspree/react";
// @ts-ignore
import EbookPDF from "../assets/jobEvenementiel.pdf";
import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
}

const FreeModal = ({ open, onClose, title }: Props) => {
  const [state, handleSubmit] = useForm("xeqvgzbb");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [name, setName] = useState("");
  const [openSuccessContent, setOpenSuccessContent] = useState(false);
  const [consent, setConsent] = useState(false);

  useEffect(() => {
    if (state.succeeded) {
      setOpenSuccessContent(true);
    }
  }, [state]);

  const isFormFilled = () => {
    return isValidEmail() && email !== "" && name !== "" && firstName !== "";
  };

  const isValidEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", md: "500px" },
          backgroundColor: "white",
          border: "2px solid #000",
          boxShadow: "24",
        }}
      >
        {!openSuccessContent ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "500px",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Typography
              sx={{ mb: 2 }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {title}
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                name="name"
                label="Nom"
                fullWidth
                autoComplete="none"
                onChange={(e) => setName(e.target.value)}
                sx={{ mb: 2 }}
                required
              />
              <TextField
                name="firstName"
                label="Prénom"
                fullWidth
                autoComplete="none"
                onChange={(e) => setFirstName(e.target.value)}
                sx={{ mb: 2 }}
                required
              />
              <TextField
                name="email"
                label="Email"
                fullWidth
                autoComplete="none"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <FormControlLabel
                sx={{ marginTop: "10px", marginBottom: "20px" }}
                label="J'accepte de recevoir des emails comprenant des tips et astuces dans l'événementiel sportif"
                control={
                  <Checkbox
                    checked={consent}
                    onChange={(e) => setConsent(e.target.checked)}
                  />
                }
              />
              <TextField
                name="fullname"
                label="Fullname"
                fullWidth
                autoComplete="none"
                sx={{ display: "none" }}
                value={
                  consent
                    ? "Souhaite être contacté"
                    : "Ne souhaite pas être contacté"
                }
                required
              />
              <Button
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  backgroundColor: "black",
                }}
                variant="contained"
                type="submit"
                disabled={!isFormFilled()}
              >
                Envoyer
              </Button>
            </form>
          </Box>
        ) : (
          <Box sx={{ p: 2 }}>
            <Typography
              sx={{ mb: 2 }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Vous pouvez désormais télécharger le ebook en cliquant sur le
              bouton ci-dessous. A bientôt !
            </Typography>
            <Button
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                backgroundColor: "black",
              }}
              variant="contained"
              href={EbookPDF}
              target="_blank"
            >
              Télécharger
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default FreeModal;
