import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// @ts-ignore
import ParisienneJPG from "../assets/laparisienne.jpeg";

// @ts-ignore
import VQHMansJPG from "../assets/vQHMans.jpg";
// @ts-ignore
import Quai54JPG from "../assets/quai54.jpg";
// @ts-ignore
import Top14JPG from "../assets/top14.jpg";
// @ts-ignore
import VQHMotoJPG from "../assets/vQHMoto.jpg";
// @ts-ignore
import NordictrackJPG from "../assets/norditrack.jpg";
// @ts-ignore
import MarathonParisJPG from "../assets/marathonParis.jpeg";
// @ts-ignore
import NBAJPG from "../assets/NBA.jpg";
// @ts-ignore
import RGJPG from "../assets/rg.jpg";
// @ts-ignore
import ParaJPG from "../assets/para.jpg";
// @ts-ignore
import FashionJPG from "../assets/fashion.jpg";
// @ts-ignore
import SemiMarathonJPG from "../assets/semiMarathon.jpeg";
// @ts-ignore
import CazooJPG from "../assets/cazoo.jpg";
// @ts-ignore
import GCPJPG from "../assets/gcp.jpg";
// @ts-ignore
import EcotrailJPG from "../assets/ecotrail.jpg";
// @ts-ignore
import BGClientJPG from "../assets/mesclientsbn.webp";

import ClientCard from "./clientCards";

const Clients = () => {
  return (
    <Box id="section">
      <Box
        sx={{
          backgroundColor: "#f1eae1",
        }}
      >
        <Box
          sx={{
            marginTop: "50px",
            paddingTop: "10px",
            backgroundColor: "black",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${BGClientJPG})`,
          }}
        >
          <Typography
            marginTop="50px"
            color="white"
            variant="h4"
            textAlign="center"
          >
            La confiance se travaille.
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-around" },
              marginTop: "50px",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "" },
            }}
          >
            <ClientCard
              image={RGJPG}
              title="Roland Garros"
              subTitle="Coordinatrice transports VIP"
            />
            <ClientCard
              image={ParaJPG}
              title="Cérémonie ouverture et clôture Jeux Paralympiques"
              subTitle="Tour leader"
            />
            <ClientCard
              image={FashionJPG}
              title="Fashion week, Tour leade"
              subTitle="Tour leader"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-around" },
              marginTop: "50px",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "" },
            }}
          >
            <ClientCard
              image={Quai54JPG}
              title="Quai 54"
              subTitle="Tour Leader"
            />
            <ClientCard
              image={Top14JPG}
              title="Finale Top 14"
              subTitle="Hospitalités / VIP"
            />
            <ClientCard
              image={VQHMansJPG}
              title="24H Lemans"
              subTitle="Conciergerie"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-around" },
              marginTop: "50px",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "" },
            }}
          >
            <ClientCard
              image={NordictrackJPG}
              title="Norditrack running Versailles"
              subTitle="Gestion des bénévoles"
            />
            <ClientCard
              image={VQHMotoJPG}
              title="24H Moto"
              subTitle="Conciergerie"
            />
            <ClientCard
              image={MarathonParisJPG}
              title="Marathon de Paris"
              subTitle="Logistique"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-around" },
              marginTop: "50px",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "" },
            }}
          >
            <ClientCard
              image={SemiMarathonJPG}
              title="Semi marathon de Paris"
              subTitle="Animation événementielle"
            />
            <ClientCard
              image={CazooJPG}
              title="Cazoo open de France"
              subTitle="Gestion des parkings"
            />
            <ClientCard
              image={NBAJPG}
              title="NBA Game Paris"
              subTitle="Gestion des transports"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-around" },
              marginTop: "50px",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "" },
              marginBottom: "10px",
            }}
          >
            <ClientCard
              image={EcotrailJPG}
              title="EcoTrail"
              subTitle="Contrôle matériel"
            />
            <ClientCard
              image={GCPJPG}
              title="Grande course du grand Paris "
              subTitle="Logistique"
            />
            <ClientCard
              image={ParisienneJPG}
              title="La Parisienne"
              subTitle="Logistique et remise dossards entreprises"
            />
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              marginTop: "30px",
              marginBottom: "30px",
            }}
            color="white"
          >
            Pour toute demande de devis, veuillez me contacter à l'adresse
            suivante : cassandrethezenas@gmail.com
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Clients;
