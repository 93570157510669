import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useForm } from "@formspree/react";
import CofeeIcon from "@mui/icons-material/Coffee";

const Contact = () => {
  const [state, handleSubmit] = useForm("xeqvgzbb");
  if (state.succeeded) {
    return <Contact />;
  }
  return (
    <Box id="section" sx={{ backgroundColor: "#f1eae1", textAlign: "center" }}>
      <Typography color="black" variant="h4">
        Contact
      </Typography>
      <Typography
        sx={{
          marginTop: "20px",
          fontSize: {
            lg: 20,
            md: 20,
            sm: 20,
            xs: 20,
          },
        }}
      >
        <CofeeIcon /> Des questions sur mon parcours ? Des besoins sur votre
        événement ? Discutons autour d'un café mocha <CofeeIcon />
      </Typography>
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Box sx={{ display: "flex", width: "500px", justifyContent: "center" }}>
          <form onSubmit={handleSubmit}>
            <TextField name="name" label="Nom" fullWidth autoComplete="none" />
            <TextField
              name="firstName"
              label="Prénom"
              fullWidth
              autoComplete="none"
            />
            <TextField
              name="email"
              label="Email"
              fullWidth
              autoComplete="none"
            />
            <TextField
              name="phone"
              label="Téléphone"
              fullWidth
              autoComplete="none"
            />
            <TextField
              name="poste"
              label="Quel est votre poste ?"
              fullWidth
              autoComplete="none"
            />
            <TextField
              name="message"
              label="Message"
              fullWidth
              multiline
              rows={5}
              autoComplete="none"
            />
            <Button
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                backgroundColor: "black",
              }}
              variant="contained"
              type="submit"
            >
              Envoyer
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
